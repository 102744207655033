.App {
  text-align: center;
}

:root {
  --polka-bg-image: radial-gradient(
    rgba(27, 235, 232, 0.1) 10%,
    transparent 14.4%
  );
  --polka-bg-position: 32px 32px;
  --polka-bg-size: 40px 40px;
  --polka-bg-color: rgba(255, 255, 255, 0);
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  background-color: #17192c;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

.headline {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 100;
  font-size: 10vw;
}

.inset {
  position: absolute;
  inset: 0;
}
.white {
  color: white;
}
.gray {
  color: #446;
}

.first-fold {
  height: 250vh;
  z-index: 1;
}

.second-fold {
  height: 200vh;
}

.third-fold {
  background: #08141c;
  /* height: 240vh; */
  position: relative;
  overflow: hidden;
  margin-top: 5%;
}

.third-fold-layout {
  position: relative !important;
  z-index: 2;
}

.fifth-fold {
  background: #08141c;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gradient {
  background: linear-gradient(rgba(14, 62, 151, 0.5) 50%, black);
}

.second-fold-layout {
  position: sticky !important;
  padding-top: 10vh;
}

.know-more-content {
  display: none;
  color: white;
  font-size: 7px;
  text-align: center;
}

.know-more-icon {
  width: 41px;
  height: 41px;
}

.scroll-none::-webkit-scrollbar {
  display: none;
}

.know-more-title {
  font-size: 16px;
  text-align: center;
}

.know-more-circle-0:hover .know-more-content {
  display: block;
}
.know-more-circle-0:hover .know-more-icon {
  width: 21px;
  height: 21px;
}
.know-more-circle-0:hover .know-more-title {
  font-size: 8px;
}

.know-more-circle-1:hover .know-more-content {
  display: block;
}

.know-more-circle-1:hover .know-more-icon {
  width: 21px;
  height: 21px;
}
.know-more-circle-1:hover .know-more-title {
  font-size: 8px;
}

.know-more-circle-2:hover .know-more-content {
  display: block;
}
.know-more-circle-2:hover .know-more-icon {
  width: 21px;
  height: 21px;
}
.know-more-circle-2:hover .know-more-title {
  font-size: 8px;
}

.know-more-circle-3:hover .know-more-content {
  display: block;
}
.know-more-circle-3:hover .know-more-icon {
  width: 21px;
  height: 21px;
}
.know-more-circle-3:hover .know-more-title {
  font-size: 8px;
}

.know-more-circle-4:hover .know-more-content {
  display: block;
}
.know-more-circle-4:hover .know-more-icon {
  width: 21px;
  height: 21px;
}
.know-more-circle-4:hover .know-more-title {
  font-size: 8px;
}

.know-more-circle-5:hover .know-more-content {
  display: block;
}
.know-more-circle-5:hover .know-more-icon {
  width: 21px;
  height: 21px;
}
.know-more-circle-5:hover .know-more-title {
  font-size: 8px;
}

.know-more-circle-6:hover .know-more-content {
  display: block;
}
.know-more-circle-6:hover .know-more-icon {
  width: 21px;
  height: 21px;
}
.know-more-circle-6:hover .know-more-title {
  font-size: 8px;
}

.know-more-circle-7:hover .know-more-content {
  display: block;
}
.know-more-circle-7:hover .know-more-icon {
  width: 21px;
  height: 21px;
}
.know-more-circle-7:hover .know-more-title {
  font-size: 8px;
}

.know-more-fold {
  transition: display 5s ease 2s;
}

.for-you-fold {
  inset: 45% 0 !important;
  height: 106vh;
}

.third-fold-avatar-container {
  width: 200px;
  height: 200px;
}

.circle-layer {
  width: 740px;
  transform: translate(-23%, 33.5%);
}
.fifth-fold-about-us .carousel .control-dots {
  text-align: center !important;
}
.fifth-fold-about-us .carousel .slide {
  text-align: center !important;
}
.fifth-fold-pricing-layout {
  position: relative !important;
}

.horizontal-scroll {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
}

.carousel .control-dots {
  text-align: left !important;
}
.carousel .slide {
  text-align: left !important;
}
.carousel .control-dots .dot {
  background: #353055 !important;
  opacity: 1 !important;
}

.carousel .control-dots .dot.selected {
  background: #1bebe7 !important;
}

.circle-layer-fifth-fold {
  height: 50vh;
}

.fifth-fold-park-icon {
  width: 520px;
  height: 520px;
}

.third-fold-dashboard {
  inset: 71% 0 !important;
}

.grid {
  background-color: transparent !important;
  background-image: linear-gradient(
      #ffffff09 0.5px,
      transparent 0.5px,
      transparent calc(100% - 1px),
      #ffffff09 calc(100% - 1px)
    ),
    linear-gradient(
      90deg,
      #ffffff09 0.5px,
      transparent 0.5px,
      transparent calc(100% - 1px),
      #ffffff09 calc(100% - 1px)
    ) !important;
  background-size: 45px 45px;
}

@media only screen and (max-width: 1280px) {
  .for-you-fold {
    inset: 25% 0 !important;
  }
  .know-more-icon {
    width: 41px;
    height: 41px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  :root {
    --polka-bg-size: 30px 30px;
    --polka-bg-color: rgba(255, 255, 255, 0);
  }
  .for-you-fold {
    inset: 5% 0 !important;
  }
  .circle-layer {
    width: 327px;
    transform: translate(-27%, 43.5%);
  }
  .know-more-content {
    font-size: 5px;
  }
  .know-more-icon {
    width: 35px;
    height: 35px;
  }
  .know-more-title {
    font-size: 13px;
    text-align: left;
  }
  .know-more-circle-0:hover .know-more-icon {
    width: 15px;
    height: 15px;
  }
  .know-more-circle-0:hover .know-more-title {
    font-size: 7px;
    text-align: left;
  }
  .know-more-circle-1:hover .know-more-icon {
    width: 15px;
    height: 15px;
  }
  .know-more-circle-1:hover .know-more-title {
    font-size: 7px;
    text-align: left;
  }
  .know-more-circle-2:hover .know-more-icon {
    width: 15px;
    height: 15px;
  }
  .know-more-circle-2:hover .know-more-title {
    font-size: 7px;
    text-align: left;
  }
  .know-more-circle-3:hover .know-more-icon {
    width: 15px;
    height: 15px;
  }
  .know-more-circle-3:hover .know-more-title {
    font-size: 7px;
    text-align: left;
  }
  .know-more-circle-4:hover .know-more-icon {
    width: 15px;
    height: 15px;
  }
  .know-more-circle-4:hover .know-more-title {
    font-size: 7px;
    text-align: left;
  }
  .know-more-circle-5:hover .know-more-icon {
    width: 15px;
    height: 15px;
  }
  .know-more-circle-5:hover .know-more-title {
    font-size: 7px;
    text-align: left;
  }
  .know-more-circle-6:hover .know-more-icon {
    width: 15px;
    height: 15px;
  }
  .know-more-circle-6:hover .know-more-title {
    font-size: 7px;
    text-align: left;
  }
  .know-more-circle-7:hover .know-more-icon {
    width: 15px;
    height: 15px;
  }
  .know-more-circle-7:hover .know-more-title {
    font-size: 7px;
    text-align: left;
  }
  .carousel .slider {
    width: 100vw !important;
  }
  .third-fold-dashboard {
    inset: 62.8% 0 !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .for-you-fold {
    inset: 10% 0 !important;
  }
  .know-more-icon {
    width: 35px;
    height: 35px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .for-you-fold {
    inset: 45% 0 !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .for-you-fold {
    inset: 5% 0 !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1400px) {
  .for-you-fold {
    inset: 5% 0 !important;
  }
  .know-more-icon {
    width: 42px;
    height: 42px;
  }
}

/* feature page css  */
.feature-page {
  overflow-y: auto;
}

.feature-page-bg::before {
  content: "";
  background-repeat: round;
  background-attachment: fixed;
  background-image: url("./images/feature-bg.png");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.2;
}

.feature-page::-webkit-scrollbar {
  display: none;
}
